import * as React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from 'styled-components'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const bgColor = '#eeefed'

  return (
    <div style={{ backgroundColor: bgColor }}>
      <Layout
        header={t("header", { returnObjects: true })}
        footer={t("footer", { returnObjects: true })}
        bgColor={bgColor}
      >
        <Styled>
          <div>
            <h1>POLÍTICA DE PRIVACIDADE</h1>
            <br /><p>A Lar Darte respeita a sua privacidade. Esta Política de Privacidade descreve quem somos, para que finalidade podemos usar os seus dados,
              como os tratamos, com quem os partilhamos, assim como as formas de entrar em contacto e de exercer os seus direitos. É nesse sentido que a Lar Darte
              apresenta a seguinte Política de Privacidade, na qual informamos sobre o modo como tratamos os seus dados pessoais e quais os seus direitos.</p><br />
            <h2>Responsável pelo Tratamento de Dados</h2><p>O responsável pelo tratamento dos seus dados é a Lar D’arte, Carpintaria e M. C., Lda., com sede em
              Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire, com o NIPC 502942762, designada nesta Política apenas por “Lar Darte”.</p><br /><h2>Politica
                de privacidade</h2> <p>Lar D’arte, Carpintaria e M. C., Lda., com sede na Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire, com capital social
                  de 150.000,00 Euros(cento e cinquenta mil euros), registado na Conservatória do Registo Comercial de Aveiro, com o número 502942762. Política de Privacidade
                  Lar Darte é responsável pela Lar Darte, adiante designado por website lardarte.pt, e compromete-se a garantir a privacidade dos dados pessoais recolhidos
                  e/ou transmitidos online. A segurança e a privacidade dos dados dos utilizadores do website lardarte.pt são aspectos que assumem para a empresa uma
                  importância crucial. <br /> Por favor, leia o texto que se segue, para compreender e tomar conhecimento da forma como as informações que disponibilizar
              serão tratadas pela Lar Darte. A Lar Darte reserva-se o direito de alterar a política de privacidade, pelo que se aconselha a leitura regular deste
              documento. Estes textos reproduzem fielmente a política de privacidade seguida pelo site lardarte.pt.</p><br /><p><b>No presente documento, poderá
                encontrar a resposta às seguintes questões: </b></p><br /><p><b>1 | </b>Qual é a informação que é recolhida sobre os utilizadores?</p><br /><p>
              <b>2 | </b>Qual é a entidade responsável pela recolha da informação?</p><br /><p><b>3 | </b>O que são cookies e como é que a Lar Darte os utiliza?
            </p><br /><p><b>4 | </b>Como é que a informação recolhida é utilizada? </p><br /><p><b>5 | </b> Que procedimentos de segurança tem o site www.lardarte.pt,
              que garantam ao utilizador a protecção dos seus dados contra o acesso, a alteração ou a destruição não autorizada? </p><br /><p><b>6 | </b>
              Que procedimentos de segurança tem o site www.lardarte.pt, que garantam ao utilizador a protecção dos seus dados contra o acesso, a alteração ou
              a destruição não autorizada? </p><br /><p><b>7 | </b>Outras questões de que deverá ter conhecimento sobre a política de privacidade da Lar Darte.
            </p><br /><p><b>Se tiver qualquer dúvida sobre alguns dos pontos aqui apresentados, agradecemos que nos comunique por email para lardarte@lardarte.pt
              ou por correio para Lar Darte, Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire.</b></p><br /><p><b>1 | Qual é a informação que é recolhida
                sobre os utilizadores?</b><br />Para que se realizar um pedido de orçamento ou de contacto através do website lardarte.pt é necessário proceder ao
              preenchimento do formulário que se encontra disponível online. A recolha dos dados pessoais é efectuada no momento do preenchimento do formulário.
              A informação recolhida é a necessária para assegurar a identidade do utilizador. No acto de registo, a informação que o utilizador disponibiliza
              tem por objectivo a prestação de um serviço mais adequado às suas características e necessidades e ao envio de informações relacionadas com os
              serviços prestados pela Lar Darte, tais como: fabrico de cozinhas ou mobiliário feito por medida ou informações gerais. O leitor poderá actualizar
              as informações por si fornecidas sempre que quiser bastando que para esse efeito, envie o pedido para lardarte@lardarte.pt. As operações de
              fornecimento de dados pessoais estão devidamente protegidas. Toda a informação é encriptada e gerida com as mais avançadas ferramentas de segurança. </p>
            <br /><p><b> 2 | Qual é a entidade responsável pela recolha da informação?</b><br />A Lar Darte é a única responsável pela recolha de dados pessoais
              efectuada no website lardarte.pt. Sempre que o utilizador termine a utilização deste serviço, a Lar Darte não assume qualquer responsabilidade pela
              informação recolhida fora das respectivas páginas electrónicas. </p><br /><p><b> 3 | O que são cookies e como é que Lar Darte os utiliza?</b>
              <br />Para poder prestar um serviço mais personalizado e ao agrado dos utilizadores, a Lar Darte utiliza cookies para recolher e guardar informação.
              Um cookie é um pequeno ficheiro informativo que é enviado do servidor do site www.lardarte.pt para o browser do utilizador e armazenado no respectivo
              computador. Estes cookies serão apenas utilizados pela Lar Darte e a sua utilização limita-se às seguintes finalidades:<br />– permitir a Lar Darte
              saber quem é o utilizador e, dessa forma, prestar um serviço melhor, mais seguro e personalizado; <br />– estimar os níveis de utilização dos serviços.
              <br />A cada utilizador corresponde um cookie individual, garantindo desta forma a privacidade e a segurança dos dados. A apresentação dos dados
              estatísticos é feita de uma forma agregada e não individual, não permitindo a identificação do utilizador. Desta forma, é possível avaliar o
              desempenho do site lardarte.pt numa perspectiva de atualização e melhoria constantes, para que se possa satisfazer os gostos e as necessidades
              do utilizador.</p><br /><p><b>4 | Como é que a informação recolhida é utilizada?</b><br />A informação recolhida servirá apenas para os seguintes
                propósitos: <br />– elaboração de estatísticas que permitam perceber como a Lar Darte poderá melhorar o nível de serviços que presta e, dessa forma,
              corresponder mais eficazmente às expectativas dos utilizadores; <br />– aumentar o nível de personalização dos serviços para que se adaptem,
              na maior medida possível, aos gostos e preferências dos utilizadores. Os dados pessoais fornecidos pelo utilizador serão utilizados exclusivamente
              para fins ligados à execução do respectivo contrato, bem como, se o utilizador assim o autorizar, para actividades de informação, marketing e
              telemarketing da Lar Darte. As comunicações de dados serão feitas mediante prévio consentimento dos titulares dos dados. Lar Darte não procederá
              à venda, aluguer, partilha ou oferta dos dados fornecidos pelos seus leitores, excepto nas situações previstas na presente política de privacidade. </p>
            <br /><p><b>5 | Que procedimentos de segurança tem o site Lar Darte que garanta aos utilizadores a protecção dos seus dados contra o acesso, a
              alteração ou a destruição não autorizada?</b><br />A Lar Darte toma todas as precauções necessárias e legalmente exigidas para garantir a protecção
              da informação recolhida junto dos seus utilizadores através do website Lar Darte. Estas precauções garantem a segurança online e offline dessa informação.
              Se tiver alguma questão a colocar-nos sobre a segurança e privacidade que a Lar Darte lhe garante, entre em contacto para +351 232 388 059 / 060 ou
              por correio para Lar Darte, Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire.</p><br /><p><b>6 | De que forma pode o utilizador corrigir ou
                eliminar as informações que facultou previamente no website da Lar Darte?</b><br />No âmbito das condições de utilização do website lardarte.pt e
              de todos os seus serviços, o utilizador compromete-se a fornecer e a manter atualizados e verdadeiros todos os seus dados pessoais. Por sua vez, a
              Lar Darte, garante ao utilizador a possibilidade de corrigir e actualizar os seus dados, devendo o utilizador contactar a Lar Darte por e-mail para
              lardarte@lardarte.pt ou por correio, para a Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire.</p><br /><p><b>7 | Outras questões de que
                deverá ter conhecimento sobre a política de privacidade do website Lar Darte: </b><br />– Alteração de Condições de Utilização do website da Lar
              Darte<br />Sempre que houver uma alteração das condições de utilização do site lardarte.pt, designadamente nas Condições de Serviço ou na Política
              de Privacidade, a Lar Darte enviará um email aos seus utilizadores. <br /> – Autorização para Utilizações Diversas <br />Se a Lar Darte pretender
              utilizar os dados dos seus utilizadores para finalidades distintas daquelas para as quais recebeu autorização inicial dos utilizadores, solicitará
              uma nova autorização dos utilizadores para essa utilização específica.</p>
          </div>
        </Styled>
      </Layout>
    </div>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`

const Styled = styled.div`
    max-width:1920px;
    margin:0 auto;
    padding: clamp(30px,10vw,60px) 5% clamp(30px,15vw,60px) 5%;
`